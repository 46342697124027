/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 @import '../../../../src/style/abstract/variables';
 @import '../../../../src/style/abstract/media';
 @import '../../../../src/style/abstract/button';
 @import '../../../../src/style/abstract/loader';
 @import '../../../../src/style/abstract/icons';
 @import '../../../../src/style/abstract/parts';
 
 .CategoryPage {
   &-TopPanel {
     background: var(--color-white);
     border-bottom: 1px solid var(--primary-divider-color);
     border-top: 1px solid var(--primary-divider-color);
     padding: 0 12px;
     position: sticky;
     width: 100%;
     z-index: 99;
     top: 101px;
   }
 
   &-Title {
     text-align: center;
     padding-block-start: 8px;
     display: none;
   }
 
   &-Filters {
     display: flex;
     justify-content: space-between;
     flex-direction: row;

     &_isSearch {
      .CategoryPage-Filter {
        @include mobile {
          width: 100%;
        }
      }
     }
   }
 
   &-Wrapper {
     .CategoryPage {
       &-ProductListWrapper {
         @include mobile {
           padding-block-start: 0px;
         }
       }
     }
   }
 
   &-SearchKeyword {
     display: inline-block;
     text-transform: uppercase;
     color: var(--primary-base-color);
     margin-inline-start: 10px;
   }
 }
 